exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-doctors-tsx": () => import("./../../../src/pages/doctors.tsx" /* webpackChunkName: "component---src-pages-doctors-tsx" */),
  "component---src-pages-duty-tsx": () => import("./../../../src/pages/duty.tsx" /* webpackChunkName: "component---src-pages-duty-tsx" */),
  "component---src-pages-facilities-tsx": () => import("./../../../src/pages/facilities.tsx" /* webpackChunkName: "component---src-pages-facilities-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-location-tsx": () => import("./../../../src/pages/location.tsx" /* webpackChunkName: "component---src-pages-location-tsx" */),
  "component---src-pages-mission-tsx": () => import("./../../../src/pages/mission.tsx" /* webpackChunkName: "component---src-pages-mission-tsx" */),
  "component---src-pages-non-reimbursement-tsx": () => import("./../../../src/pages/non-reimbursement.tsx" /* webpackChunkName: "component---src-pages-non-reimbursement-tsx" */),
  "component---src-pages-rehab-index-tsx": () => import("./../../../src/pages/rehab/index.tsx" /* webpackChunkName: "component---src-pages-rehab-index-tsx" */),
  "component---src-pages-rehab-operational-tsx": () => import("./../../../src/pages/rehab/operational.tsx" /* webpackChunkName: "component---src-pages-rehab-operational-tsx" */),
  "component---src-pages-rehab-physical-tsx": () => import("./../../../src/pages/rehab/physical.tsx" /* webpackChunkName: "component---src-pages-rehab-physical-tsx" */),
  "component---src-pages-schedule-tsx": () => import("./../../../src/pages/schedule.tsx" /* webpackChunkName: "component---src-pages-schedule-tsx" */)
}

